export enum ROUTES {
  ROOT = "/",
  UTILIZATION = "usage/",
  DOCUMENTSERVICES = "documentservices/",
}

export enum EXTERNAL_LINKS {
  HEALTH_MINISTRY = "https://www.salute.gov.it/portale/home.html",
  DIGITAL_TRASFORMATION_DEPARTMENT = "https://innovazione.gov.it/",
  OPEN_DATA = "https://dati.gov.it/",
}

export enum UTILIZATION_API_PATH {
  FSE = "utilization/Indicator3_2.json",
  CONSENT = "utilization/Indicator3_3.json",
  MEDICS = "utilization/utilization_medics.json",
  MEDICS_OPS = "utilization/Indicator3_5.json",
  COMPANIES = "utilization/Indicator3_1.json",
}

export enum DOCUMENT_API_PATH {
  DOCUMENTS = "documents/documents.json",
  DOCUMENTS_PER_REGION = "documents/documents_per_region.json",
  SERVICES = "documents/services.json",
  SERVICES_PER_REGION = "documents/services_per_region.json",
}
